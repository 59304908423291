
import { defineComponent, ref } from 'vue';

import { useSubscriptions } from '@/composables/api';

import { SubscriptionRenew } from '@/interfaces/Subscription';
import SearchBox from '@/components/keyword-searching/Index.vue';

export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const { data, isLoading, isFetching } = useSubscriptions({ page, keyword });

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      SubscriptionRenew,
      searchKeyword
    };
  }
});
