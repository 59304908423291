export enum SubscriptionRenew {
  YES = 1,
  NO = 0
}

export interface Subscription {
  id: number
  username: string
  plan: string
  email: string
  started_at: string
  ended_at: string
  renew: SubscriptionRenew
  created_at: string
}

export type PartialSubscription = Partial<Subscription>;
